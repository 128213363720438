/* eslint-env node */

const moneyFormat =
  (locale, opts) =>
  ({ amount, currency }) => {
    if (currency === "") {
      return `${amount / 100}`;
    }

    const format = new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
      ...opts,
    });

    return format.format(amount / 100);
  };

const isZonedDate = (date) => "date" in date;

module.exports = {
  i18n: {
    locales: ["default", "en", "es", "fr", "pt"],

    defaultLocale: "default",
  },
  serializeConfig: false,
  use: [
    {
      init: (i18next) => {
        i18next.services.formatter.add("money", (value, _, lang) =>
          moneyFormat(lang, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            currencyDisplay: "narrowSymbol",
          })(value),
        );
        i18next.services.formatter.add("moneyShort", (value, _, lang) =>
          moneyFormat(lang, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            currencyDisplay: "narrowSymbol",
          })(value),
        );
        i18next.services.formatter.add("moneyShortSigned", (value, _, lang) =>
          moneyFormat(lang, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
            signDisplay: "exceptZero",
            currencyDisplay: "narrowSymbol",
          })(value),
        );
        i18next.services.formatter.add("dateTime", (value, _, lang) => {
          const dateFmt = new Intl.DateTimeFormat(lang, {
            dateStyle: "medium",
          });
          const timeFmt = new Intl.DateTimeFormat(lang, { timeStyle: "short" });
          const d = isZonedDate(value) ? value.date : value;
          return `${dateFmt.format(d)} ${timeFmt.format(d)}`;
        });
        i18next.services.formatter.add("date", (value, _, lang) => {
          const dateFmt = new Intl.DateTimeFormat(lang, {
            dateStyle: "medium",
          });
          const d = isZonedDate(value) ? value.date : value;
          return dateFmt.format(d);
        });
        i18next.services.formatter.add("time", (value, _, lang) => {
          const timeFmt = new Intl.DateTimeFormat(lang, { timeStyle: "short" });
          const d = isZonedDate(value) ? value.date : value;
          return timeFmt.format(d);
        });
      },
      type: "3rdParty",
    },
  ],
};

import { Provider as DesignSystemProvider } from "@liberetech/design-system/Context";
import { NavLinkProps } from "@liberetech/design-system/NavLink";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AppProps as NextAppProps } from "next/app";
import Head from "next/head";
import { appWithTranslation } from "next-i18next";
import { ReactNode } from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import nextI18NextConfig from "../../next-i18next.config";

import { Provider as BrandProvider } from "components/Context/BrandContext";
import ExperimentContext from "components/Context/ExperimentContext";
import { Provider as OnlineReceptionProvider } from "components/Context/OnlineReceptionContext";
import { Provider as PageProvider } from "components/Context/PageContext";
import { Provider as SessionProvider } from "components/Context/SessionContext";
import { Provider as TrackingProvider } from "components/Context/TrackingContext";
import Layout from "components/Layout/Layout";
// eslint-disable-next-line no-restricted-imports
import NextLink from "components/NextLink/NextLink";
import { DefaultSeo, LanguageAlternates } from "components/Seo/Seo";
import { TrackingContextPage } from "lib/tracking";
import { CommonProps, Page } from "types";
import "./_app-reset.css";
import "./_app.css";

const queryClient = new QueryClient();

const NavLink: React.FC<NavLinkProps> = ({ href, children, ...props }) => {
  const hasLocale = href && /^\/..(\/|$)/.test(href);

  return (
    <NextLink href={href || ""} locale={hasLocale ? false : undefined}>
      <a {...props}>{children}</a>
    </NextLink>
  );
};

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  const {
    brand,
    content,
    languageAlternates,
    page,
    trackingContext,
    breadcrumbs,
    environment,
  } = pageProps;

  return (
    <SessionProvider>
      <BrandProvider brand={brand}>
        <ExperimentContext
          environment={environment}
          trackingContext={trackingContext}
        >
          <OnlineReceptionProvider>
            <PageProvider>
              <TrackingProvider trackingContext={trackingContext}>
                <DesignSystemProvider navLinkComponent={NavLink}>
                  <QueryClientProvider client={queryClient}>
                    {brand ? (
                      <>
                        <Head>
                          {brand?.design?.favicon &&
                            Object.keys(brand?.design?.favicon ?? {}).map(
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              (size: any) => (
                                <link
                                  key={size}
                                  rel="icon"
                                  type="image/png"
                                  href={brand.design?.favicon?.[size]}
                                  sizes={`${size}x${size}`}
                                />
                              ),
                            )}
                          <link
                            rel="preload"
                            href={brand.stylesUrl}
                            as="style"
                          />
                          {brand.fontsUrl?.map((fontUrl: string, i: number) => (
                            <link
                              key={i}
                              rel="preload"
                              href={fontUrl}
                              as="font"
                              crossOrigin="anonymous"
                            />
                          ))}
                        </Head>
                        <DefaultSeo
                          brand={brand}
                          languageAlternates={languageAlternates}
                        />
                        <Layout
                          brand={brand}
                          content={content}
                          languageAlternates={languageAlternates}
                          breadcrumbs={breadcrumbs}
                          page={page}
                        >
                          <Component {...pageProps} />
                        </Layout>
                      </>
                    ) : (
                      <Component {...pageProps} />
                    )}
                  </QueryClientProvider>
                </DesignSystemProvider>
              </TrackingProvider>
            </PageProvider>
          </OnlineReceptionProvider>
        </ExperimentContext>
      </BrandProvider>
    </SessionProvider>
  );
};

type AppProps = NextAppProps & {
  pageProps: CommonProps & {
    page?: Page;
    languageAlternates?: LanguageAlternates;
    trackingContext: TrackingContextPage;
    children?: ReactNode;
  };
};

export default appWithTranslation(MyApp, nextI18NextConfig);

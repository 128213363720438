import { nanoid } from "nanoid";
import { createContext, useContext, useEffect, useState } from "react";

import { identityClient } from "lib/client/identity";
import { useLanguage } from "lib/i18n/client";

const SessionContext = createContext<ContextType>({
  inhabitant: null,
  refresh: () => Promise.resolve(),
});

const COOKIE_ANON_ID = "li_anon";
const COOKIE_EXPIRATION = 3600 * 1000 * 24 * 365; // 1 year

export const Provider: React.FC = ({ children }) => {
  const [inhabitant, setInhabitant] = useState<Session | null>(null);
  const lang = useLanguage();

  const refresh = async () => {
    try {
      const me = await identityClient(lang).me();
      setInhabitant({
        inhabitantId: me.data.id,
        verified: me.data.verified,
        anonId: getAnonId(),
      });
    } catch {
      setInhabitant({ anonId: getAnonId() });
    }
  };

  useEffect(() => {
    (async () => {
      await refresh();
    })();
  }, []);

  return (
    <SessionContext.Provider value={{ inhabitant, refresh }}>
      {children}
    </SessionContext.Provider>
  );
};

const getAnonId = (): string => {
  const [, id] =
    document.cookie.match(new RegExp(`(?:^| )${COOKIE_ANON_ID}=([^;]+)`)) || [];

  if (id) {
    return id;
  }

  const newId = nanoid();
  const expiration = new Date();
  expiration.setTime(expiration.getTime() + COOKIE_EXPIRATION);

  document.cookie = `${COOKIE_ANON_ID}=${newId};expires=${expiration.toUTCString()};Path=/;`;

  return newId;
};

export const useSession: () => ContextType = () => {
  const session = useContext(SessionContext);
  return session;
};

type ContextType = { inhabitant: Session | null; refresh: () => Promise<void> };

export type Session = {
  anonId: string;
  inhabitantId?: string;
  verified?: boolean;
};

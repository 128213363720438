import Link from "@liberetech/design-system/Link";
import NavLink from "@liberetech/design-system/NavLink";
import useRipple from "@liberetech/design-system/useRipple";
import classNames from "classnames";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useRef, useState } from "react";

import AppBadgeAndroid from "components/AppBadge/AppBadgeAndroid";
import AppBadgeIOS from "components/AppBadge/AppBadgeIOS";
import { useOnlineReception } from "components/Context/OnlineReceptionContext";
import { useDevice, usePage } from "components/Context/PageContext";
import { useSession } from "components/Context/SessionContext";
import { useTrackingContext } from "components/Context/TrackingContext";
import { BreadcrumbJsonLd, LanguageAlternates } from "components/Seo/Seo";
import { useTranslation } from "lib/i18n/client";
import { Brand, Content, Page } from "types";

import styles from "./Layout.module.css";
import LayoutContactIcon from "./LayoutContactIcon/LayoutContactIcon";
import LayoutLanguageSelector from "./LayoutLanguageSelector/LayoutLanguageSelector";
import LayoutNavBar from "./LayoutNavBar/LayoutNavBar";
import LayoutOnlineReception from "./LayoutOnlineReception/LayoutOnlineReception";
import LayoutProgressBar from "./LayoutProgressBar/LayoutProgressBar";

const LayoutCookieBanner = dynamic(
  () => import("./LayoutCookieBanner/LayoutCookieBanner"),
);
const LayoutIdleLogoutModal = dynamic(
  () => import("./LayoutIdleLogoutModal/LayoutIdleLogoutModal"),
);
const LayoutSideMenu = dynamic(() => import("./LayoutSideMenu/LayoutSideMenu"));
const LayoutSearchBar = dynamic(
  () => import("./LayoutSearchBar/LayoutSearchBar"),
);

const Layout: React.FC<LayoutType> = ({
  children,
  brand,
  content,
  languageAlternates,
  breadcrumbs,
  className,
  page,
}) => {
  const { inhabitant } = useSession();
  const { isConfigured: isTrackingConfigured } = useTrackingContext();
  const { t } = useTranslation("common");
  const onlineReception = useOnlineReception();
  const router = useRouter();
  const legacyPage = usePage();
  const { isNativeApp, isKiosk } = useDevice();
  const [isCookieBannerVisible, setCookieBannerVisible] = useState(false);
  const [isSideMenuVisible, setSideMenuVisible] = useState(false);
  const [isKeyButtonVisible, setKeyButtonVisible] = useState(false);
  const [isScrolled, setScrolled] = useState(false);
  useEffect(() => {
    setCookieBannerVisible(!isTrackingConfigured);
    setKeyButtonVisible(!!(isNativeApp && inhabitant?.inhabitantId));
  }, [inhabitant, isTrackingConfigured]);

  useEffect(() => {
    const onScroll = () => {
      setScrolled(document.documentElement.scrollTop > 10);
    };
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    if (isKiosk) {
      document.body.classList.add("isKiosk");
    }
    return () => {
      document.body.classList.remove("isKiosk");
    };
  }, [isKiosk]);

  const alternates =
    languageAlternates ||
    router.locales
      ?.filter((locale) => locale != "default")
      .map((locale) => ({
        href: `/${locale}${router.asPath}`,
        hrefLang: locale,
      })) ||
    [];
  const isMyPlace = router.pathname.startsWith("/myplace");
  const focusMode =
    router.pathname.includes("/wizard/") || router.pathname.includes("/book");
  const isRootPage = !legacyPage || page?.isRootPage;
  const backHrefRef = useRef<HTMLDivElement>(null);
  useRipple(backHrefRef);
  return (
    <>
      <Head>
        <link rel="stylesheet" href={brand.stylesUrl} />
      </Head>
      <LayoutProgressBar />
      <div
        className={classNames(styles.layout, className, {
          [styles.layoutRootPage]: isRootPage,
          [styles.layoutFocusMode]: focusMode,
          [styles.layoutWithSearchBar]: page?.searchBar,
          [styles.layoutHeaderTransparent]:
            page?.searchBar || page?.isHeaderTransparent,
          [styles.layoutIsScrolled]: isScrolled,
        })}
      >
        <header className={styles.header}>
          <div className={styles.headerWrap}>
            <div className={styles.headerLogo}>
              <NavLink
                aria-label={t("header.toHomepage")}
                className={styles.headerLogoLink}
                href="/"
                dangerouslySetInnerHTML={{ __html: brand.logo }}
              />
            </div>
            <div className={styles.headerMobileNav}>
              {(legacyPage?.backHref || legacyPage?.backOnClick) && (
                <div className={styles.headerMobileBack} ref={backHrefRef}>
                  {legacyPage.backHref && (
                    <NavLink
                      href={legacyPage?.backHref}
                      onClick={legacyPage?.backOnClick}
                    >
                      <IconBack />
                    </NavLink>
                  )}
                  {!legacyPage.backHref && legacyPage.backOnClick && (
                    <button
                      className={styles.headerMobileBackButton}
                      onClick={legacyPage.backOnClick}
                    >
                      <IconBack />
                    </button>
                  )}
                </div>
              )}
              {legacyPage?.title && (
                <p className={styles.headerMobileNavTitle}>
                  {legacyPage.title}
                </p>
              )}
            </div>
            {page?.searchBar && (
              <LayoutSearchBar
                className={styles.searchBar}
                isScrolled={isScrolled}
                options={page.searchBar.assets || page.searchBar.destinations}
                optionsDefault={page.searchBar.optionsDefault}
                type={page.searchBar.assets ? "asset" : "destination"}
                brand={brand}
              />
            )}
            <div className={styles.headerActions}>
              <button
                className={classNames(
                  styles.headerAction,
                  styles.headerActionContact,
                )}
                onClick={() => {
                  onlineReception.open();
                  window.dataLayer?.push({
                    event: "open",
                    event_category: "online_reception",
                    event_label: "header",
                  });
                }}
              >
                <LayoutContactIcon />
                <span>{t("onlineReception.link")}</span>
              </button>
              {!focusMode && (
                <NavLink className={styles.headerAction} href="/myplace">
                  <svg viewBox="0 0 24 24">
                    <path d="M19.15 20.69c-.14-2.45-1.53-3.22-3.57-3.9-1.46-.48-1.93-1.96-2.08-2.85M10.5 13.94c-.16.9-.62 2.37-2.08 2.85-2.04.68-3.44 1.45-3.58 3.89" />
                    <path d="M12 14.25a3.75 3.75 0 01-3.75-3.75V9a3.75 3.75 0 017.5 0v1.5A3.75 3.75 0 0112 14.25z" />
                    <path d="M23.24 12.53A11.25 11.25 0 10.76 11.47a11.25 11.25 0 0022.48 1.06z" />
                  </svg>
                  {t("header.myplace")}
                </NavLink>
              )}
              {!focusMode && isMyPlace && (
                <button
                  aria-label={t("header.logout")}
                  className={classNames(
                    styles.headerAction,
                    styles.headerActionLogout,
                  )}
                  onClick={() => {
                    window.dataLayer.push({
                      event: "sign_out",
                      event_category: "account",
                      event_label: "sign_out_button",
                    });
                    document.location.href = "/logout";
                  }}
                >
                  <span>{t("header.logout")}</span>
                  <svg viewBox="0 0 20 24">
                    <path d="M5 12h13.5" />
                    <path d="M13.25 17.25L18.5 12l-5.25-5.25" />
                    <path d="M16.25 4.5V.75h-15v22.5h15V19.5" />
                  </svg>
                </button>
              )}
              {(router.pathname.endsWith("/help") || focusMode) && (
                <LayoutLanguageSelector
                  className={styles.headerLanguageSelector}
                  languageAlternates={alternates}
                />
              )}
            </div>
          </div>
        </header>
        <main className={styles.main}>{children}</main>
        {!focusMode && (
          <>
            <LayoutNavBar
              keyButton={isKeyButtonVisible}
              onMenuClick={() => {
                setSideMenuVisible(true);
              }}
              onContactClick={() => {
                onlineReception.open();
                window.dataLayer?.push({
                  event: "open",
                  event_category: "online_reception",
                  event_label: "navbar",
                });
              }}
            />
            <LayoutSideMenu
              isOpen={isSideMenuVisible}
              content={content}
              languageAlternates={alternates}
              onRequestClose={() => {
                setSideMenuVisible(false);
              }}
              isMyPlace={isMyPlace}
            />
            <div className={styles.preFooter}>
              <div className="grid">
                <div className={styles.preFooterContent}>
                  {breadcrumbs && (
                    <nav>
                      <ul className={styles.preFooterBreadcrumbs}>
                        {breadcrumbs.map((breadcrumb, idx) => (
                          <li key={idx}>
                            <NavLink href={breadcrumb.item}>
                              {breadcrumb.name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  )}
                </div>
              </div>
            </div>
            <footer className={styles.footer}>
              <div className="grid">
                <div className={styles.footerBrand}>
                  <div
                    className={styles.footerLogo}
                    dangerouslySetInnerHTML={{ __html: brand.logo }}
                  />
                  {brand.slogan && (
                    <p className={styles.footerSlogan}>{brand.slogan}</p>
                  )}
                  <div className={styles.footerNavGroupLeft}>
                    {!isNativeApp && (
                      <>
                        <h3 className={styles.footerNavHeader}>
                          {t("footer.selectLanguage")}
                        </h3>
                        <LayoutLanguageSelector
                          className={styles.footerLanguageSelector}
                          languageAlternates={alternates}
                        />
                      </>
                    )}
                  </div>
                  {!isNativeApp && brand.app?.store && (
                    <div className={styles.footerNavGroupLeft}>
                      <h3 className={styles.footerNavHeader}>
                        {t("footer.downloadApp")}
                      </h3>
                      <div className={styles.footerAppBadges}>
                        <a
                          aria-label="App Store"
                          href={brand.app.store.ios}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                          onClick={() => {
                            window.dataLayer.push({
                              event: "click",
                              event_category: "native_app_download",
                              event_label: "ios",
                            });
                          }}
                        >
                          <AppBadgeIOS />
                        </a>
                        <a
                          aria-label="Google Play"
                          href={brand.app.store.android}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                          onClick={() => {
                            window.dataLayer.push({
                              event: "click",
                              event_category: "native_app_download",
                              event_label: "android",
                            });
                          }}
                        >
                          <AppBadgeAndroid />
                        </a>
                      </div>
                    </div>
                  )}
                  <div className={styles.footerNavGroupLeft}>
                    <h3 className={styles.footerNavHeader}>
                      {t("footer.follow-us")}
                    </h3>
                    <ul className={styles.footerSocial}>
                      {brand.social?.instagram && (
                        <li>
                          <a
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                            href={`https://www.instagram.com/${brand.social.instagram}`}
                          >
                            <svg aria-label="Instagram" viewBox="0 0 24 24">
                              <rect width="16" height="16" x="4" y="4" rx="4" />
                              <circle cx="12" cy="12" r="3" />
                              <path d="M16.5 7.5v.001" />
                            </svg>
                          </a>
                        </li>
                      )}
                      {brand.social?.facebook && (
                        <li>
                          <a
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                            href={`https://www.facebook.com/${brand.social.facebook}`}
                          >
                            <svg aria-label="Facebook" viewBox="0 0 24 24">
                              <path d="M7 10v4h3v7h4v-7h3l1-4h-4V8a1 1 0 0 1 1-1h3V3h-3a5 5 0 0 0-5 5v2H7" />
                            </svg>
                          </a>
                        </li>
                      )}
                      {brand.social?.twitter && (
                        <li>
                          <a
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                            href={`https://twitter.com/${brand.social.twitter}`}
                          >
                            <svg aria-label="Twitter" viewBox="0 0 24 24">
                              <path d="M22 4.01c-1 .49-1.98.689-3 .99-1.121-1.265-2.783-1.335-4.38-.737S11.977 6.323 12 8v1c-3.245.083-6.135-1.395-8-4 0 0-4.182 7.433 4 11-1.872 1.247-3.739 2.088-6 2 3.308 1.803 6.913 2.423 10.034 1.517 3.58-1.04 6.522-3.723 7.651-7.742a13.84 13.84 0 0 0 .497-3.753C20.18 7.773 21.692 5.25 22 4.009z" />
                            </svg>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <nav className={styles.footerNav}>
                  <ul className={styles.footerNavList}>
                    {content.footerMenu?.map((item, i) => (
                      <li className={styles.footerNavGroup} key={i}>
                        <span className={styles.footerNavHeader}>
                          {item.title}
                        </span>
                        {item.children && (
                          <ul className={styles.footerNavLinks}>
                            {item.children.map(({ title, href, props }, j) => (
                              <li key={j}>
                                <NavLink href={href} {...props}>
                                  {title}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
              <div className={classNames("grid", styles.footerBottom)}>
                <p>
                  <Link>
                    <a href="https://www.liberehospitality.com" target="_blank">
                      Líbere Hospitality S.L.
                    </a>
                  </Link>{" "}
                  {new Date().getFullYear()} All rights reserved.
                </p>
              </div>
            </footer>
          </>
        )}
        {isCookieBannerVisible && (
          <LayoutCookieBanner
            onClose={() => {
              setCookieBannerVisible(false);
            }}
          />
        )}
        {isKiosk && <LayoutIdleLogoutModal />}
        <LayoutOnlineReception />
      </div>
      {breadcrumbs && (
        <BreadcrumbJsonLd
          itemListElements={breadcrumbs.map((breadcrumb, i) => ({
            position: i,
            name: breadcrumb.name,
            item: `https://${brand.currentHost}${breadcrumb.item}`,
          }))}
        />
      )}
    </>
  );
};

const IconBack: React.FC = () => (
  <svg fill="none" viewBox="0 0 10 16">
    <path d="M8 14L2 8l6-6" />
  </svg>
);

type LayoutType = {
  brand: Brand;
  content: Content | Record<string, never>;
  children: ReactNode;
  languageAlternates?: LanguageAlternates;
  breadcrumbs?: Array<{ name: string; item: string }>;
  className?: string;
  page?: Page;
};

export default Layout;

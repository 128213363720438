import NavLink from "@liberetech/design-system/NavLink";
import { HTMLAttributeAnchorTarget, ReactNode } from "react";

import ListItem from "./ListItem";
import styles from "./ListItemLink.module.css";

const ListItemLink: React.FC<ListItemLinkProps> = ({
  href,
  target,
  onClick,
  title,
  children,
  startElement,
  className,
  type,
}) => {
  const LinkWrapper: React.FC = ({ children }) => (
    <NavLink href={href} onClick={onClick} target={target}>
      {children}
    </NavLink>
  );

  const ButtonWrapper: React.FC = ({ children }) => (
    <button onClick={onClick}>{children}</button>
  );

  return (
    <ListItem
      className={className}
      startElement={startElement}
      wrapperAs={href ? LinkWrapper : ButtonWrapper}
      title={title}
      endElement={
        <svg className={styles.arrow} viewBox="0 0 24 24">
          <path d="M10.2 8.8999L13.8 12.4999L10.2 16.0999" />
        </svg>
      }
      type={type}
    >
      {children}
    </ListItem>
  );
};

type ListItemLinkProps = {
  className?: string;
  startElement: ReactNode;
  title: ReactNode | string;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
  type?: "default" | "info" | "error" | "success" | "warn";
  onClick?: () => void;
};

export default ListItemLink;

// eslint-disable-next-line no-restricted-imports
import {
  GrowthBook,
  GrowthBookProvider,
  useFeature,
  useFeatureIsOn,
  useFeatureValue,
  useGrowthBook,
} from "@growthbook/growthbook-react";
import { useEffect, useMemo } from "react";

import { useLanguage } from "lib/i18n/client";
import { TrackingContextPage } from "lib/tracking";

import { useSession } from "./SessionContext";

const ExperimentContext: React.FC<ExperimentContextProps> = ({
  children,
  environment,
  trackingContext,
}) => {
  const lang = useLanguage();
  const growthbook = useMemo(
    () =>
      new GrowthBook({
        apiHost: "https://feature-flags.libere.tech",
        clientKey:
          environment === "production"
            ? "sdk-PVzE604HSXubs6h"
            : "sdk-kK2KE7g4VclGMewN",
        enableDevMode: process.env.NODE_ENV === "development",
        trackingCallback: (experiment, result) => {
          window.dataLayer.push({
            event: "experiment_seen",
            event_category: experiment.key,
            event_value: result.variationId,
          });
        },
      }),
    [environment],
  );

  const session = useSession();
  useEffect(() => {
    if (session.inhabitant?.anonId) {
      growthbook.setAttributes({
        ...growthbook.getAttributes(),
        anonId: session.inhabitant.anonId,
        inhabitantId: session.inhabitant.inhabitantId,
        ...(trackingContext
          ? {
              purchaseId: trackingContext.purchase_id,
              reservationId: trackingContext.reservation_id,
              assetId: trackingContext.asset_id,
            }
          : undefined),
      });
      growthbook.loadFeatures({ autoRefresh: true });
    }
  }, [session.inhabitant, trackingContext]);

  useEffect(() => {
    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      locale: lang,
    });
  }, [lang]);

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
};

type ExperimentContextProps = {
  environment: string;
  trackingContext?: TrackingContextPage;
};

const useFeaturesReady = () => {
  const gb = useGrowthBook();
  return gb ? gb.ready : false;
};

export { useFeature, useFeatureIsOn, useFeatureValue, useFeaturesReady };

export default ExperimentContext;
